import React from 'react'
import './index.css'

export enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary'
}

export enum ButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large'
}

interface ButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'> {
  variant?: ButtonType
  size?: ButtonSize
  btnClassName?: string
  children: React.ReactNode
}

const SynturaBtn: React.FC<ButtonProps> = ({ children, variant, size = ButtonSize.MEDIUM, ...props }) => {
  const className = () => {
    let classNames = 'syntura-btn';

    if (variant) {
      classNames += ` ${variant}`;
    }

    if (size) {
      classNames += ` ${size}`;
    }

    return classNames;
  };

  return (
    <button className={`${className()} ${props.btnClassName || ''}`} {...props}>
      {children}
    </button>
  );
};

export default SynturaBtn
