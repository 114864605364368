import React, {useState} from 'react';
import {connect, ConnectedProps} from 'react-redux'
import {RootState} from "../../store";
import {CellularSharp, FingerPrintSharp, MailUnreadSharp} from "react-ionicons";
import {
    useMfaMethodsQuery,
    useSendMfaMutation,
    hsoIdApi,
    useLazyMfaResendQuery, useSetMfaMethodMutation
} from "../../services/hsoid";
import {useAppDispatch} from "../../hooks";
import {MfaMethod} from "../../types";
import MfaSetup from "../mfa/mfasetup";
import * as bulmaToast from 'bulma-toast'
import SynturaBtn, { ButtonType } from '../../components/SynturaBtn';


const mapState = (state: RootState) => ({
  authentication: state.authentication
});

type PropsFromRedux = ConnectedProps<typeof connector>

interface MyProps {
    method: string;
}


type Props = PropsFromRedux & MyProps;
const MfaPromptComponent = (props: Props) => {

    const {data, error, isLoading} = useMfaMethodsQuery();
    const [doMfa, { data: mfaData, isLoading: isMfaLoading, isError, error: mfaError, isSuccess: isMfaSuccess }] =  useSendMfaMutation();
    const [mfacode, setMfaCode] = useState<string>('');
    const dispatch = useAppDispatch();
    const [mfaCodeError, setMfaCodeError] = useState<boolean>(false);
    const [requestMfaResend] = useLazyMfaResendQuery();
    const [showMethods, setShowMethods] = useState<boolean>(false);
    const [setMfaMethod] = useSetMfaMethodMutation();

    if(isLoading) {
        return(<p><progress className="progress is-small is-primary" max="100">15%</progress></p>)
    }

    const resendMfaCode = () => {
        requestMfaResend().then((data) => {
            if(data.isSuccess) {
                bulmaToast.toast({
                    duration: 2500,
                    position: 'top-center',
                    message: 'New Code Sent',
                    type: 'is-success',
                    dismissible: true
                });
            }
            else {
                bulmaToast.toast({
                    duration: 2500,
                    position: 'top-center',
                    message: 'Could not send a new code',
                    type: 'is-danger',
                    dismissible: true
                });
            }
        }).catch(() => {
            bulmaToast.toast({
                duration: 2500,
                position: 'top-center',
                message: 'Could not send a new code',
                type: 'is-danger',
                dismissible: true
            });
        });
    }

    const submitMfa = (e: React.FormEvent) => {
        e.preventDefault();
        doMfa({code: mfacode}).then((c) => {
            if('error' in c) {
                setMfaCodeError(true);
            }
            else {
                dispatch(hsoIdApi.endpoints.mfaMethods.initiate());
                dispatch(hsoIdApi.endpoints.authStatus.initiate());
            }
        }
        );
    }

    if(data) {
        if(data.filter(m => m.active === true).length === 0) {
            return(<MfaSetup initial={true}/>)
        }
        const method: MfaMethod = data.filter(m => m.id === props.method)[0]
        if(!method) {
            return(<progress className="progress is-small is-primary" max="100">15%</progress>)
        }
        return (
            <div className="container">
                <div className="content">
                    <div className="columns" style={{ margin: 0, alignItems: 'center', height: '100vh' }}>
                        <div className="column is-mobile is-three-quarters-mobile is-offset-2-mobile is-half is-offset-one-quarter">
                            <div className="card">
                                <div className="card-content card-white-background">
                                    {method.method === 'EMAIL' && <p>Enter the code sent to your email ({method.target}) below:</p>}
                                    {method.method === 'TOTP' && <p>Enter the code from your mobile application ({method.target}) below:</p>}
                                    {method.method === 'SMS' && <p>Enter the code sent via SMS to your phone (+{method.target}) below:</p>}
                                    {isMfaLoading === true &&
                                    <progress className="progress is-small is-primary" max="100">15%</progress>}
                                    <form onSubmit={submitMfa}>
                                        <div className="field">
                                            <label className="label-secondary">One Time Code</label>
                                            <div className="control has-icons-left has-icons-right">
                                                <input className={"input is-medium" + (mfaCodeError? " is-danger": "is-black")} type="text" placeholder="Code"
                                                       onChange={e => setMfaCode(e.target.value)} value={mfacode} />
                                                <span className="icon is-small is-left">
                                                  <FingerPrintSharp color={"#000"}/>
                                                </span>
                                            </div>
                                            {mfaCodeError && <p className="help is-danger">Incorrect MFA Code</p>}
                                        </div>
                                        <div className="has-text-right mt-3">
                                            <SynturaBtn
                                                disabled={isMfaLoading}
                                                onClick={submitMfa}
                                                variant={ButtonType.PRIMARY}
                                            >
                                                Authenticate
                                            </SynturaBtn>
                                        </div>
                                    </form>
                                    {method.method !== 'TOTP' &&
                                        <p style={{ fontSize: '15px' }}>Not received it? <a onClick={resendMfaCode}>Resend Code</a></p>
                                    }
                                    {data.filter(m => m.active).length > 1 &&
                                        <div>
                                            <p>Try an <a onClick={() => setShowMethods(true)}>alternate method</a>?</p>
                                            {showMethods &&
                                                <div>
                                                {data.map((mfam: MfaMethod) => {
                                                    if(mfam.id !== method.id && method.active) {
                                                        return (
                                                            <a className="panel-block"
                                                               onClick={() => { setMfaMethod({method: mfam.id}); setShowMethods(false); }}>
                                                                {mfam.method === 'EMAIL' &&
                                                                    <MailUnreadSharp color={"#000"}
                                                                                     style={{marginRight: '10px'}}/>}
                                                                {mfam.method === 'SMS' &&
                                                                    <CellularSharp color={"##000"}
                                                                                   style={{marginRight: '10px'}}/>}
                                                                {mfam.method === 'TOTP' &&
                                                                    <><FingerPrintSharp color={"##000"}
                                                                                      style={{marginRight: '10px'}}/>
                                                                    Token: </>}
                                                                {mfam.method === 'SMS' && "+"}{mfam.target}
                                                            </a>
                                                        )
                                                    }
                                                })}
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return null;
}

const connector = connect(mapState);

export default connector(MfaPromptComponent);