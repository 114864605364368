import React, {useState} from "react";
import {useAddNewClientMutation} from "../../services/hsoid";
import {useNavigate} from "react-router-dom";

const AddClient = () => {

    const [addClient, shiz] = useAddNewClientMutation();
    const [cName, setCName] = useState<string>('');
    const nav = useNavigate();
    const submitNewClient = (e: React.FormEvent) => {
        e.preventDefault();
        addClient({
            client_name: cName,
            require_auth_time: false
        }).then((d) => {
            if('data' in d) {
                nav('/clients/' + d.data?.client_id);
            }
        });
    }

    return(
        <div className="container">
            <div className="content">
                <div className="columns mt-6">
                    <div className="column">
                        <h1 className="title">Add New API Client</h1>
                        <div className={"box"}>
                            <form onSubmit={submitNewClient}>
                                <div className="field">
                                    <label className="label ">Name</label>
                                    <div className="control">
                                        <input className="input" type="text" placeholder="Name of Secret" onChange={e => setCName(e.target.value)} value={cName} />
                                    </div>
                                </div>
                                <div className="has-text-right">
                                    <button className="button is-primary" disabled={false}>Add</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddClient;