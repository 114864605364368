import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from "../../store";
import { ReactComponent as SynturaLogo } from "../../../Syntura_logo.svg";
import { LockClosedSharp, PersonSharp } from "react-ionicons";
import { useLoginMutation } from '../../services/hsoid';
import SynturaBtn, { ButtonType } from '../../components/SynturaBtn';


const mapState = (state: RootState) => ({
    authentication: state
});

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux;

const LoginComponent = (props: Props) => {

    const [username, setUserName] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const [doLogin, { data, isLoading, isError, error, isSuccess }] = useLoginMutation();

    const submitLogin = (e: React.FormEvent) => {
        e.preventDefault();
        doLogin({
            username,
            password
        });
    }

    return (
        <div className="container">
            <div className="content">
                <div className="columns" style={{margin: 0, alignItems: 'center', height: '100vh'}}>
                    <div className="column is-mobile mt-6 is-10-mobile is-offset-1-mobile is-half is-offset-one-quarter">
                        <div className="card card-white-background ">
                            <div className="card-content">
                                <form onSubmit={submitLogin}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '7rem' }}>
                                        <SynturaLogo width={300} height={80}/>
                                    </div>
                                    <h1 className="title has-text-centered is-4 mt-4 mb-4">Customer Login</h1>
                                    {error !== undefined && "status" in error &&
                                        <div className="notification is-danger">Login failed</div>
                                    }
                                    {isLoading === true &&
                                        <progress className="progress is-small is-primary" max="100">15%</progress>}
                                    <div className="field">
                                        <label className="is-black">Username</label>
                                        <div className="control has-icons-left has-icons-right">
                                            <input className="input is-medium" type="text" placeholder="Username"
                                                onChange={e => setUserName(e.target.value)} value={username} />
                                            <span className="icon is-small is-left">
                                                <PersonSharp color={"#000"} />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="is-black">Password</label>
                                        <div className="control has-icons-left has-icons-right">
                                            <input className="input is-medium" type="password" placeholder="Password"
                                                onChange={e => setPassword(e.target.value)} value={password} />
                                            <span className="icon is-small is-left">
                                                <LockClosedSharp color="#000" />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="has-text-right mt-3">
                                        <SynturaBtn
                                            disabled={isLoading}
                                            onClick={submitLogin}
                                            variant={ButtonType.PRIMARY}
                                        >
                                            Login
                                        </SynturaBtn>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const connector = connect(mapState);

export default connector(LoginComponent);
